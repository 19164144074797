import React from 'react';
import styles from './ModalLayoutBasicFooter.scss';
import { PRIORITY } from 'wix-ui-tpa/Button';
import dataHooks from '../../data-hooks';
import { TranslationFunction } from 'i18next';
import { translate } from 'yoshi-flow-editor-runtime';
import Button from '../Button';
import Text from '../../core-components/Text';
import classNames from 'classnames';

export interface ModalLayoutBasicFooterProps {
  onOkClick: () => void;
  onCancelClick: () => void;
  t: TranslationFunction;
  isMobile: boolean;
  centered?: boolean;
  borderTop?: boolean;
  className?: string;
  fullWidthMobile?: boolean;
  isSaveBtnDisabled?: boolean;
}

const ModalLayoutBasicFooter: React.FC<ModalLayoutBasicFooterProps> = ({
  onOkClick,
  onCancelClick,
  t,
  isMobile,
  centered,
  borderTop,
  className = '',
  fullWidthMobile = '',
  isSaveBtnDisabled,
}) => {
  const cls = classNames({
    [styles.wrapper]: true,
    [className]: Boolean(className),
    [styles.mobile]: isMobile,
    [styles.centered]: centered,
    [styles.borderTop]: borderTop,
    [styles.fullWidthMobile]: fullWidthMobile,
  });

  return (
    <div className={cls} data-hook="modal-layout-basic-footer">
      <Button
        upgrade
        className={styles.button}
        fullWidth={isMobile}
        onClick={() => onCancelClick()}
        priority={PRIORITY.secondary}
        data-hook={dataHooks.dispatchSummaryButtonCancel}
      >
        <Text typography="p2-m-colorless">{t('order_settings_modal_button_cancel')}</Text>
      </Button>
      <div className={`${styles.spacer} ${isMobile && styles.mobile}`} />
      <Button
        upgrade
        className={styles.button}
        fullWidth={isMobile}
        onClick={() => onOkClick()}
        priority={PRIORITY.primary}
        data-hook={dataHooks.dispatchSummaryButtonOk}
        disabled={isSaveBtnDisabled}
      >
        <Text typography="p2-m-colorless">{t('order_settings_modal_button_save')}</Text>
      </Button>
    </div>
  );
};

ModalLayoutBasicFooter.displayName = 'ModalLayoutBasicFooter';

export default translate()(ModalLayoutBasicFooter);
