import { TranslationFunction } from 'i18next';
import {
  DispatchType,
  DispatchInfo,
  Availability,
  IntegerInterval,
  getDateOptions,
  getAsapParams,
  getDaysDiff,
  getTimeOptions,
  Restaurant,
} from '@wix/restaurants-client-logic';
import { DropdownOptionProps } from 'wix-ui-tpa/dist/src/components/Dropdown/DropdownOption';

interface getAsapTextArgs {
  deliveryInfos: DispatchInfo[];
  timezone: string;
  dispatchType: DispatchType;
  t: TranslationFunction;
  idealDeliveryArea?: DispatchInfo;
  locale: string;
}

export const getParamsForIntlFormat = (valueInMins?: number): { value: number; timeUnit: TimeUnit } => {
  if (!valueInMins) {
    return { value: 0, timeUnit: 'minute' };
  }
  const HOUR = 60;
  const DAY = 60 * 24;
  return valueInMins % DAY === 0
    ? {
        value: valueInMins / DAY,
        timeUnit: 'day',
      }
    : valueInMins % HOUR === 0
    ? { value: valueInMins / 60, timeUnit: 'hour' }
    : {
        value: valueInMins,
        timeUnit: 'minute',
      };
};

type TimeUnit = 'minute' | 'day' | 'hour';

const humanizeMinutesDuration = (locale = 'en_US', timeFormatParams: { value: number; timeUnit: TimeUnit | '' }) => {
  if (timeFormatParams === undefined || timeFormatParams.timeUnit === '') {
    return '';
  }
  // @ts-ignore
  const rtf = new Intl.RelativeTimeFormat(locale.split('_')[0], { numeric: 'auto' });
  return rtf.format(timeFormatParams.value, timeFormatParams.timeUnit);
};

const getHuminzedTimeUnit = (t: TranslationFunction, timeFormatParams: { value: number; timeUnit: string }) => {
  return timeFormatParams.value > 1
    ? t(`checkout_main_deliverymethod_time_${timeFormatParams.timeUnit}s_label`)
    : t(`checkout_main_deliverymethod_time_${timeFormatParams.timeUnit}_label`);
};

export function getAsapText({ idealDeliveryArea, deliveryInfos, dispatchType, t, locale }: getAsapTextArgs): string {
  const asapParams = getAsapParams({ idealDeliveryArea, deliveryInfos, dispatchType });
  let text;
  switch (asapParams.type) {
    case 'unavailable':
    case 'invalid':
    case 'immediate':
      text = '';
      break;
    case 'range':
      const { min, max } = asapParams;
      const minTimeParams = getParamsForIntlFormat(min);
      const maxTimeParams = getParamsForIntlFormat(max);
      const minString =
        minTimeParams.timeUnit !== maxTimeParams.timeUnit
          ? `${minTimeParams.value} ${getHuminzedTimeUnit(t, minTimeParams)}`
          : minTimeParams.value;

      const maxString = `${maxTimeParams.value} ${getHuminzedTimeUnit(t, maxTimeParams)}`;
      text = t('main_page_minmaxminutes', { min: minString, max: maxString });
      break;
    case 'exact':
      text =
        dispatchType === 'delivery'
          ? t('main_page_maxminutes', {
              delayMin: `${getParamsForIntlFormat(asapParams.value).value} ${getHuminzedTimeUnit(
                t,
                getParamsForIntlFormat(asapParams.value),
              )}`,
            })
          : `(${humanizeMinutesDuration(locale, getParamsForIntlFormat(asapParams.value))})`;

      break;
    default:
      break;
  }
  return `${t('main_page_asap')} ${text}`;
}

export const formatDate = (timestamp: number, timezone: string, fallback: string, t: TranslationFunction): string => {
  const diff = getDaysDiff(timestamp, timezone);

  if (diff === 0) {
    return t('order_settings_modal_dropdown_today');
  } else if (diff === 1) {
    return t('order_settings_modal_dropdown_tomorrow');
  } else {
    return fallback;
  }
};

export const getDateDropdownOptions = (
  availability: Availability,
  timezone: string,
  numberOfDays: number,
  delayMins: IntegerInterval,
  t: TranslationFunction,
  locale: string,
  dishPrepareTime: number,
): DropdownOptionProps[] => {
  if (locale === undefined) {
    return [];
  }
  // @ts-ignore
  const intl = new Intl.DateTimeFormat(locale.split('_')[0], { day: 'numeric', month: 'long', timeZone: timezone });
  return getDateOptions({ availability, timezone, numberOfDays, delayMins, dishPrepareTime }).map((o) => ({
    id: String(o.timestamp),
    value: formatDate(o.timestamp, timezone, intl.format(o.timestamp), t),
    isSelectable: true,
  }));
};

export const getTimeDropdownOptions = (
  availability: Availability,
  timezone: string,
  selectedDate: number,
  delayMins: IntegerInterval,
  dishPrepareTime: number,
): DropdownOptionProps[] => {
  return getTimeOptions({ availability, day: selectedDate, timezone, delayMins, dishPrepareTime }).map((o) => ({
    id: String(o.timestamp),
    value: o.displayableValue,
    isSelectable: true,
  }));
};

export function getDishPrepareTime(restaurant: Restaurant, dispatchType: DispatchType) {
  const takeoutInfo = restaurant.deliveryInfos.find((di) => di.type === 'takeout');

  if (dispatchType === 'takeout' && takeoutInfo) {
    return takeoutInfo.delayMins || 0;
  } else {
    return 0;
  }
}
